export default class SocketClient{
    socket:WebSocket;
    eos:Function|null;
    url:string ;

    constructor(pUrl:string,pEos:Function|null = null){
        if(!pUrl) console.error("URL is required for sockets");
        this.url = `wss://${location.host}/${pUrl}`;
        this.eos = pEos;
                
    }
    
    async send(pData:any,pCallback:Function) {
        try{
            await this._openConection();
        }catch(ex){

            return Promise.reject(ex);
        }
        
        
        return new Promise((resolve)=>{
            this.socket.onclose = function (event) {
                return resolve(true);
            };
            this.socket.onmessage =  (event) =>{
                let vReturn = tryParseJson(event.data);

                if(this.eos && this.eos.call(this,vReturn)){
                     return resolve(true);
                }
                if(vReturn.hasOwnProperty("EOS")){
                    //this.close();
                    return resolve(true);
                }
                if(pCallback) pCallback.call(this,vReturn);
            };
            this.socket.send(JSON.stringify(pData));
        })
    }
    close(){
        if(this.socket){ //&& this.socket.readyState === 1){
            this.socket.close(4000,  'User cancelled');
            
        }
    }
    async _openConection():Promise<WebSocket>{
        if(this.socket && this.socket.readyState === 1) return Promise.resolve(this.socket);
        this.socket = new WebSocket(this.url);

      //  this._initEvents();
        return new Promise(resolve=>{
            this.socket.onopen = ()=>{
                
                return resolve(this.socket);
            }
        });
    }
    _initEvents(){
        this.socket.onerror = function(event) { 
            console.log("error",event)
        };
    }
    
}
function tryParseJson(pText:string){
    try{
        return JSON.parse(pText);
    }catch{
        return pText;
    }
}

